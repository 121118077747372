
import Slider from "@/components/base/input/Slider"
import SpriteRatingEmoji from "@/components/base/image/SpriteRatingEmoji"
import { mapGetters } from "vuex"
import { OABALYTICS_RATING_SLIDER } from "@/enums/oabalytics"

let trackedData = []

export default {
    name: "EmojiRatingSlider",
    components: { Slider, SpriteRatingEmoji },
    emits: ["input", "mouseup"],
    props: {
        job: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        value: null,
    }),
    computed: {
        ...mapGetters("rating", ["getRating"]),
        internalValue: {
            get() {
                return this.value ?? this.rating ?? 50
            },
            set(val) {
                trackedData.push(val)
                this.value = val

                this.$emit("input", val)
            },
        },
        rating() {
            if (!this.job) return this.value
            return this.getRating(this.job.id)
        },
        currentIndex() {
            const EMOJI_COUNT = 10
            return Math.min(
                Math.floor(this.internalValue / EMOJI_COUNT),
                EMOJI_COUNT - 1
            )
        },
        isDisabled() {
            return !this.$can.like() && this.rating !== undefined
        },
    },
    watch: {
        /** Case: Entry got deleted */
        rating(newVal) {
            if (newVal === undefined) {
                this.value = null
            }
        },
    },
    methods: {
        onMouseUp() {
            this.sendTrackedData()
            if (this.job) {
                this.$store.dispatch("rating/rateJob", {
                    job: this.job,
                    value: this.internalValue,
                })
            }
            this.$emit("mouseup")
        },
        sendTrackedData() {
            this.$oabalytics.trackEvent(OABALYTICS_RATING_SLIDER, trackedData)
            trackedData = []
        },
    },
}
