
import { getImageTypeFromPath, getImageFormatFromPath } from "@/helpers/image"

export default {
    name: "Sprite",
    props: {
        value: {
            type: Number,
            default: 0,
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        src: {
            required: true,
            type: String,
        },
        alt: {
            type: String,
            default: "",
        },
        loading: {
            type: String,
            default: "eager",
        },
        fallbacks: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        fall: require("@/assets/icons/emojis_sprite.webp"),
    }),
    computed: {
        wrapperStyles() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
            }
        },
        imageStyles() {
            return {
                left: `-${this.value * this.width}px`,
            }
        },
        validFallbacks() {
            return this.fallbacks.filter((f) => {
                return !!getImageFormatFromPath(f)
            })
        },
        lastImage() {
            return this.validFallbacks.length > 0
                ? this.validFallbacks[this.validFallbacks.length - 1]
                : this.src
        },
    },
    methods: {
        getImageTypeFromPath,
    },
}
