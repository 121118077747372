
export default {
    name: "Slider",
    props: {
        label: {
            type: String,
            default: "",
        },
        value: {
            type: Number,
            default: 0,
        },
        mapping: {
            type: Function,
            default: (v) => v,
        },
        /** Inverted version for dark design */
        dark: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {}
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                return this.$emit("input", value)
            },
        },
    },
}
