
import EmojiRatingSlider from "@/components/base/input/EmojiRatingSlider"
import SpriteRatingEmoji from "@/components/base/image/SpriteRatingEmoji"
import Button from "@/components/base/Button"
import { OABALYTICS_RATING_MENU } from "@/enums/oabalytics"

import { mapGetters } from "vuex"

export default {
    name: "EmojiRatingMenu",
    components: { SpriteRatingEmoji, EmojiRatingSlider, Button },
    emits: ["input", "removed"],

    props: {
        job: {
            type: Object,
            required: true,
        },
        /** v-model for menu */
        value: {
            type: Boolean,
            default: false,
        },
        /** Dark mode */
        dark: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("rating", ["getRating"]),
        model: {
            get() {
                return this.value
            },
            set(val) {
                this.$oabalytics.trackEvent(OABALYTICS_RATING_MENU, {
                    action: val ? "open" : "close",
                    job_id: this.job.id,
                })
                this.$emit("input", val)
            },
        },
        currentIndex() {
            const EMOJI_LENGTH = 10
            const rating = this.getRating(this.job.id) ?? 50
            return Math.min(Math.floor(rating / EMOJI_LENGTH), EMOJI_LENGTH - 1)
        },
        isRated() {
            return this.getRating(this.job.id) !== undefined
        },
    },
    methods: {
        onRemoveRating() {
            this.$emit("input", false)
            this.$store
                .dispatch("rating/removeRating", this.job.id)
                .then(() => {
                    this.$emit("removed")
                })
        },
    },
}
