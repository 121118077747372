/**
 * @param {Number} size File size from large (1) to small (3)
 */
export function getImage(image, size = 3, format = null) {
    let fileEnding = format || supportsWebp() ? "webp" : "jpg"
    const returnImage = {}

    if (supportsWebp()) {
        returnImage.src = require("@/assets/images/placeholder.webp")
    } else {
        returnImage.src = require("@/assets/images/placeholder.jpg")
    }
    if (!image) return returnImage /* Use Placeholder */
    if (!image.urls && image.local)
        return { src: image.local } /* Use local file */
    if (image.urls) {
        const fileSuffix = `${size}.${fileEnding}`
        const placeholderSuffix = `p.${fileEnding}`
        returnImage.src = image.urls[fileSuffix]
        returnImage.placeholder = image.urls[placeholderSuffix]
    }
    return returnImage
}

export function getImageFormatFromPath(path) {
    const imageFormatRegex = /[^/]+\.(jpg|jpeg|png|gif|bmp|webp)/i
    const formatMatch = path.match(imageFormatRegex)
    if (formatMatch) {
        return formatMatch[1]
    }
    return null
}

export function getImageTypeFromPath(path) {
    const format = getImageFormatFromPath(path)
    if (format) {
        return `image/${format}`
    }
    return null
}

function supportsWebp() {
    return true // Check to be created
}
