
import Sprite from "@/components/base/image/Sprite"

export default {
    name: "SpriteRatingEmoji",
    components: {
        Sprite,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
    },
}
